@tailwind base;
@tailwind components;
@tailwind utilities;



html {

  --csgreen: #14F195;
  --csblue: #03E1FF;
  --cspink: #DC1FFF;
  --cspurple: #9945FF;


  background-color: #000;
  color: #FFF;
}


.MuiButton-label{
  /* font-family: 'river_adventurer' !important;
  letter-spacing: 3px; */

}

.App {
  text-align: center;
}


.site-header{
  /* background-color: rgba(0,0,0,0.6); */
  padding: 20px;
  z-index: 900;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  font-family: 'river_adventurer';
  letter-spacing: 2px;

}
.site-header .minted{
  display: inline-block;
  color: #376c50;
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  .site-header .minted{
    display: none;
  }
}

.site-header .btn{

  margin-left: 20px;
  background: #000;
  color: #FFF;
  padding: 10px;
  border-radius: 4px;
  transition: 0.3s;

  padding: 6px 15px!important;
  font-size: 22px;
  background-color: #000!important;
  /* font-family: monospace!important; */
  text-transform: uppercase!important;
  border: 1px solid!important;
  border-radius: 6px!important;
  /* margin: 10px auto!important; */

  transition: .3s;
  line-height: 50px;
  background: -webkit-linear-gradient(0deg,var(--cspurple) 0,var(--cspink) 100%);
  /* color: var(--csblue); */
  border-image-slice: 1;
  border-image-source: linear-gradient(0deg,var(--csgreen) 0,var(--cspurple) 100%);

}

@media only screen and (max-width: 767px) {
  .site-header .btn{
    font-size: 16px;
    margin: 0 auto;
  }
}
.site-header .btn:hover{
background: linear-gradient(0deg,var(--cspurple) 0%,var(--cspink) 100%);
}

.video-bg video{
  object-fit: cover;
  min-height: 100vh;

}

.video-bg video.ui-taffy{
  object-fit: cover;
  min-height: 100vh;
  width: 50vw;

}

@media only screen and (max-width: 767px) {
  .video-bg video.ui-taffy{
    display: none;
  
  }
}


img.ui-bus{
  object-fit: cover;
  min-height: 100vh;

  animation: mymove 20s 1;
  animation-timing-function: ease-in-out;
  transform: translateX(-30%);
  max-width: none;
}

@keyframes mymove {
  from {transform: translateX(-50%);}
  to {transform: translateX(-30%);}
}


.logo{

  max-height: 100px;


}

.showPrice{
  font-family: 'river_adventurer';
  letter-spacing: 1px;
  font-size: 40px;
  text-align: center;

}

.ui-disconnect {
  display: flex;
  justify-content: center;
}
.ui-disconnect .wallet-adapter-button{
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  margin: 6px 0 10px;
  color: #878787;
}

.ui-disconnect .wallet-adapter-button-start-icon{

  display: none;
}